<template>
  <depenses :key="key" v-on:refrech="refrech"></depenses>
</template>
<script>
/* eslint-disable */
import depenses from "./depenses";
export default {
  components: {
    depenses,
  },
  data() {
    return { key: 0 };
  },
  methods: {
    refrech() {
      this.key = this.key + 1;
    },
  },
};
</script>